<template>
  <div>
    <el-row id="movie-list">
      <el-scrollbar style="width: 100%; height: 75vh;">
        <el-row
          v-if="dataList.length !== 0"
          v-infinite-scroll="load"
          infinite-scroll-disabled="loading"
          infinite-scroll-distance="10"
        >
          <el-col v-for="(video, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
            <video-card :video="video" />
          </el-col>
          <el-col v-if="dataList.length === 0" class="not-result" :md="6" :sm="12" :xs="12">
            <img src="@/assets/img/icon/not-result.png">
            <div>没有视频数据</div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </el-row>
  </div>
</template>

<script>
import VideoCard from 'components/card/VideoCard'
import { categoryShortVideos } from '@/api/video'

export default {
  name: 'ShortVideo',
  components: { VideoCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      categoryId: 1,
      treeNode: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        value: 'value'
      },
      loading: false
    }
  },
  created() {
    document.title = '短视频主页'
    this.videoPageWrapper(this.categoryId, this.currentPage)
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    videoPageWrapper(categoryId, currentPage) {
      categoryShortVideos(categoryId, currentPage).then(resp => {
        if (resp.code === 0) {
          for (const item of resp.data.list) {
            this.dataList.push(item)
          }

          this.currentPage += 1
          this.loading = false
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    load() {
      this.loading = true
      setTimeout(() => {
        this.videoPageWrapper(this.categoryId, this.currentPage)
      }, 1000)
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  #movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

#movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
</style>
